import React, { useState, useEffect } from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { WindowLocation } from "@reach/router"
import { Sun, Moon } from "react-feather"

import Navbar from "./navigation"

import Footer from "./footer"
import SEO, { SEOProps } from "../utils/seo"

import "../style/index.css"
import { ThemeQuery } from "./__generated__/ThemeQuery"
import CookieBox from "./cookie"
import store from "../utils/store"
import { motion } from "framer-motion"

export type Theme = { name: string; label: string; icon: JSX.Element }
type LayoutProps = {
    children: any
    front?: boolean
    seo: Partial<SEOProps>
    navPlaceholder?: boolean
    location: WindowLocation
}

export default ({
    children,
    front,
    seo,
    navPlaceholder = true,
    location,
}: LayoutProps) => {
    const query = useStaticQuery<ThemeQuery>(graphql`
        query ThemeQuery {
            site {
                siteMetadata {
                    icon
                    switchTheme
                    darkmode
                    cookiePolicy
                }
            }
        }
    `)

    const themes: Theme[] = [
        {
            name: "theme-light",
            label: "Light Theme",
            icon: <Sun />,
        },
        {
            name: "theme-dark",
            label: "Dark Theme",
            icon: <Moon />,
        },
    ]

    const isDarkTheme = query.site.siteMetadata.darkmode
    const cookiePolicyEnabled = query.site.siteMetadata.cookiePolicy

    const [theme, changeTheme] = useState(isDarkTheme ? 1 : 0)
    const [cookieShown, setCookieShown] = useState(false)

    useEffect(() => {
        if (localStorage.getItem("theme")) {
            const t = Number(localStorage.getItem("theme"))
            changeTheme(t)
        }

        if (localStorage.getItem("cookie-accept")) {
            setCookieShown(true)
        }
    }, [])

    const onCookieAccept = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault()

        localStorage.setItem("cookie-accept", "1")
        setCookieShown(true)
    }

    const switchTheme = () => {
        const next = theme !== themes.length - 1 ? theme + 1 : 0
        changeTheme(next)
        localStorage.setItem("theme", `${next}`)
        store.dispatch("theme:change", undefined)
    }

    return (
        <React.Fragment>
            <Head data={query} />
            <SEO {...seo} />
            <div
                className={`wrapper ${themes[theme].name} flex flex-col min-h-screen`}
            >
                <motion.div
                    className="text-color-default bg-bg flex-grow"
                    animate={{
                        // x: 0,
                        opacity: 1,
                    }}
                    initial={{
                        // x: 100,
                        opacity: 0,
                    }}
                    exit={{
                        // x: -100,
                        opacity: 0,
                    }}
                    transition={{
                        duration: 0.8,
                    }}
                >
                    <Navbar
                        front={front}
                        navPlaceholder={navPlaceholder}
                        location={location}
                        currentTheme={theme}
                        switchTheme={switchTheme}
                        themes={themes}
                        allowThemeSwitch={query.site.siteMetadata.switchTheme}
                    />
                    {children}
                </motion.div>

                <Footer />
                {query.site.siteMetadata.cookiePolicy && !cookieShown && (
                    <CookieBox onChange={onCookieAccept} />
                )}
            </div>
        </React.Fragment>
    )
}

const Head = ({ data }) => {
    return (
        <Helmet>
            <link
                rel="icon"
                href={data.site.siteMetadata.icon}
                type="image/png"
            />
            <link
                href="https://fonts.googleapis.com/css?family=Raleway:500,800&display=swap"
                rel="stylesheet"
            />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link
                href="https://fonts.googleapis.com/css2?family=Open+Sans&family=Raleway&display=swap"
                rel="stylesheet"
            />
            {/* <link
                rel="stylesheet"
                href="https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.4.1/css/swiper.css"
            />
            <link
                rel="stylesheet"
                href="https://unpkg.com/swiper/css/swiper.min.css"
            /> */}
            
        </Helmet>
    )
}
